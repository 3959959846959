import { Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import {
  Check as AuthorizedIcon,
  Dehaze as PendingIcon,
  Clear as UnauthorizedIcon,
} from '@material-ui/icons';
import firebase from 'firebase';
import React, { FC, Fragment, useMemo, useState } from 'react';
import {
  ArrayField,
  ChipField,
  FunctionField,
  SelectField,
  SingleFieldList,
  useMutation,
} from 'react-admin';
import ObserverRowDownloadPdf from '../../Components/participation/ObserverRowDownloadPdf';
import ParticipationAddButton from '../../Components/participation/ParticipationAddButton';
import ParticipationAddOfferButton from '../../Components/participation/ParticipationAddOfferButton';
import ParticipationEditOrRemoveOfferButton from '../../Components/participation/ParticipationEditOrRemoveOfferButton';
import ParticipationRowCheck from '../../Components/participation/ParticipationRowCheck';
import ParticipationRowDownloadPdf from '../../Components/participation/ParticipationRowDownloadPdf';
import { ImportParticipationButton } from '../../Components/participation/a107/import-participation-button';
import AttachmentsButton from '../../Components/participation/attachments/attachments-button';
import { GenerateNewPinButton } from '../../Components/participation/generate-new-pin-button';
import AppRelatedResource from '../../Components/related-resource/app-related-resource';
import ParticipationDetails from '../../Components/sale-experiment/participation-details/participation-details';
import AppCard from '../../Components/ui/card/AppCard';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import { AppLinkToEntityField } from '../../Components/ui/field';
import AppDateField from '../../Components/ui/field/AppDateField';
import AppTextField from '../../Components/ui/field/AppTextField';
import AppFormTab from '../../Components/ui/form/AppFormTab';
import AppTabbedForm from '../../Components/ui/form/AppTabbedForm';
import AppTooltip from '../../Components/ui/tooltip/AppTooltip';
import { useDidMount } from '../../hooks/use-did-mount';
import { ItemGenre, ORIGIN_TYPES, SaleMode } from '../../utils/constants';
import { amountFormatter } from '../../utils/data-formatters';
import {
  useAuctionHandler,
  useFirebaseApp,
  useOnlineSubscriptionHandler,
} from '../../utils/firebase';
import { calcPercentageOfValue } from '../../utils/numbers';
import { PERSON_TYPES } from '../Booking/constants';
import { PAYMENT_METHODS } from '../Payment/constants';
import AuctionControlButton from './Auction/AuctionControlButton';
import AuctionManagement from './Auction/AuctionManagement/auction-management';
import SaleExperimentAdvertisementOutcome from './SaleExperimentAdvertisementOutcome';
import { SaleExperimentAuctionDetails } from './SaleExperimentDetailComponents';
import SaleExperimentDetailsLog from './SaleExperimentDetailsLog';
import AppParticipantManager from './participant-manager';
import SaleExperimentDetailsShipping from './SaleExperimentDetailsShipping';

const appRelatedResourceStyles = makeStyles((theme) => ({
  toolbar: {
    //backgroundColor: 'red',
    minHeight: 'auto',
    ['& button']: {
      position: 'absolute',
      right: theme.spacing(8),
      top: -theme.spacing(4),
    },
  },
  // -----------------
  createdAt: {
    whiteSpace: 'nowrap',
  },
  name: {
    whiteSpace: 'nowrap',
    minWidth: theme.spacing(30),
  },
  amount: {
    whiteSpace: 'nowrap',
    minWidth: theme.spacing(20),
  },
  errorMessage: {
    whiteSpace: 'nowrap',
    minWidth: theme.spacing(30),
  },
  lastCol: {
    whiteSpace: 'nowrap',
    width: '100%',
  },
}));

const datagridStyles = makeStyles((theme) => ({
  colOrigin: {
    minWidth: theme.spacing(10), //80px
  },
  colPresenter: {
    minWidth: theme.spacing(27),
  },
  colObserverReason: {
    minWidth: theme.spacing(40),
  },
  colBidders: {
    minWidth: theme.spacing(40),
  },
  colDepositAmount: {
    whiteSpace: 'nowrap',
    minWidth: theme.spacing(20),
    textAlign: 'right',
  },
  colPaymentMethod: {
    whiteSpace: 'nowrap',
    minWidth: theme.spacing(20),
  },
  colFeesAmount: {
    whiteSpace: 'nowrap',
    minWidth: theme.spacing(20),
    textAlign: 'right',
  },
  colAmount: {
    minWidth: theme.spacing(20),
    textAlign: 'right',
  },
  colOfferedAt: {
    minWidth: theme.spacing(24),
  },
  colMediaNumber: {
    minWidth: theme.spacing(25),
  },
  mediaNumberContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  colFullWidth: {
    width: '100%',
  },
  // ------------
  cellAuthorized: {
    color: theme.palette.success.main,
  },
  cellUnauthorized: {
    color: theme.palette.error.main,
  },
  cellPending: {
    color: theme.palette.warning.main,
  },
  cellCenter: {
    textAlign: 'center',
  },
}));

/*
// NOTE: test to avoid props drilling and improve performances
export const SaleExperimentDetailsContext = createContext<Record<string, any>>({
  isAdvertisement: false,
});
SaleExperimentDetailsContext.displayName = 'SaleExperimentDetailsContext';
 */

type Offer = {
  offer: number;
  bidder: number;
  origin: string;
  type: string;
  bidType?: 'bid-edit' | 'bid-cancellation';
  editable: boolean;
};

const PARTICIPANT_BASE_SOURCE = 'frontEndParticipant';

const IS_IVG = process.env.REACT_APP_IS_IVG === 'true';

const SaleExperimentDetails: FC<any> = React.memo((props) => {
  const classes = appRelatedResourceStyles(props);
  const dgClasses = datagridStyles();
  const { record: saleExperiment } = props;

  /// region SETTINGS
  const cannotModify = useMemo(() => {
    if (props.mode !== 'edit' || process.env.REACT_APP_ENVIRONMENT !== 'production') return false;

    const saleExperiment = props.record;
    const today = new Date();
    const midnight = new Date();
    today.setHours(23, 59, 59, 999);
    midnight.setHours(0, 0, 0, 0);
    const auctionStart = new Date(
      saleExperiment.auctionStartAt ??
        saleExperiment.expressionOfInterestStartAt ??
        saleExperiment.offersCollectionStartAt ??
        saleExperiment.retailStartAt ??
        saleExperiment.buyItNowStartAt,
    );
    return (
      (auctionStart <= today && auctionStart >= midnight) ||
      saleExperiment.auctionStatus !== 'inactive'
    );
  }, [props.record, props.mode]);

  const isAsync = useMemo(
    () =>
      saleExperiment.fkSaleMode === SaleMode.AsyncOnline &&
      saleExperiment.relatedItem.fkGenre !== ItemGenre.RealEstate,
    [saleExperiment],
  );

  const isExpressionOfInterestOrOffersCollection = useMemo(
    () =>
      [SaleMode.OffersCollection, SaleMode.ExpressionOfInterest].includes(
        saleExperiment.fkSaleMode,
      ),
    [saleExperiment],
  );

  const isJudiciary = useMemo(
    () => saleExperiment.relatedItem?.relatedFolder?.type === 'judiciary',
    [saleExperiment],
  );

  const isAdvertisement = useMemo(
    () =>
      !saleExperiment.relatedItem?.handleSale &&
      saleExperiment.fkSaleMode !== SaleMode.ExpressionOfInterest &&
      saleExperiment.fkSaleMode !== SaleMode.OffersCollection,
    [saleExperiment],
  );

  const isRetailSale = useMemo(
    () => saleExperiment.fkSaleMode === SaleMode.Retail,
    [saleExperiment],
  );

  const isBuyItNowSale = useMemo(
    () => saleExperiment.fkSaleMode === SaleMode.BuyItNow,
    [saleExperiment],
  );

  /// endregion

  /// region FIREBASE DATA

  /// region FIREBASE STATUSES

  // TODO: use React Context to avoid PROPS DRILLING (e.g. raiseLimitTS is passed down to 4/5 components <.< ...bad)
  const [offerSuggestion, setOfferSuggestion] = useState<number>(0);
  const [bestOffer, setBestOffer] = useState<Record<string, any>>({});
  const offers: Offer[] = [];
  const [raiseLimitTS, setRaiseLimitTS] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [completedAuction, setCompletedAuction] = useState<Record<string, any> | null>(null);
  const [newFrontEndParticipant, setNewFrontEndParticipant] = useState<Record<string, any>>();
  const [reservePriceInfo, setReservePriceInfo] = useState<Record<string, any>>({
    reached: false,
    removed: false,
    ownerChoice: null,
    matched: false,
    dueDate: null,
    renounced: false,
    waitingForOwner: false,
    waitingForWinner: false,
  });
  let lastReservePrice = -1;
  const [isConnectionOk, setConnectionOk] = useState<boolean>(true);

  const [serverTimeDeviceTimeOffset, setServerTimeDeviceTimeOffset] = useState(0);
  const [mutateServerNow] = useMutation();
  useDidMount(() => {
    mutateServerNow(
      {
        type: 'getOne',
        resource: 'publications/server-now-admin',
        payload: {},
      },
      {
        onSuccess: (res) => setServerTimeDeviceTimeOffset(res.data.id - Date.now()),
        onFailure: (err) => {
          console.error('Could not get server NOW:', err);
        },
      },
    );
  });

  /// endregion

  useDidMount(() => {
    // initialize firebase app
    useFirebaseApp();

    // Auction events
    useAuctionHandler(props.id, (data) => {
      const {
        status,
        best,
        raiseLimitTS,
        offer,
        bid,
        participationId,
        origin,
        type,
        kind,
        action,
        reached,
        dueDate,
        waitingForOwner,
        waitingForWinner,
        newReservePrice,
      } = data;

      if (type === 'notice' && kind === 'ReservePriceInfo') {
        if (action === 'reduced') {
          lastReservePrice = newReservePrice;
        } else if (action === 'removed') {
          // set removed to true
          setReservePriceInfo((prevState) => ({
            ...prevState,
            removed: true,
          }));
          lastReservePrice = -1;
        }
        return setReservePriceInfo((prevState) => ({
          ...prevState,
          reached,
          ownerChoice:
            action === 'accepted' ? 'accepted' : action === 'rejected' ? 'rejected' : null,
          matched: action === 'matched',
          dueDate: dueDate ? new Date(dueDate) : null,
          renounced: action === 'renounced',
          waitingForOwner,
          waitingForWinner,
          newReservePrice: newReservePrice ?? lastReservePrice ?? -1,
        }));
      }

      if (status === 'completed') {
        setIsActive(false);
        return setCompletedAuction(data);
      }

      if (status === 'paused') {
        return setIsPaused(true);
      }

      if (status === 'waiting') {
        // in case auction is postponed but was active (returns to "wait" status)
        setIsActive(false);
      }

      if (status === 'active') {
        setIsActive(true);
      }

      if (best || (type && (type === 'bid-edit' || type === 'bid-cancellation'))) {
        const bestOffer: Offer = {
          offer: offer ?? bid ?? 0,
          bidder: participationId,
          origin: origin,
          type: offer ? 'offer' : 'bid',
          bidType: !offer ? type : null,
          editable: true,
        };
        offers.push(bestOffer);

        //if (bestOffer) {
        const currentBestOffer = getBestOffer();
        setBestOffer(currentBestOffer);
        setOfferSuggestion(
          currentBestOffer.offer +
            (props.record.minRaiseRefTo === 'minRaisePercentage'
              ? calcPercentageOfValue(currentBestOffer.offer, props.record.minRaisePercentage)
              : props.record.minRaise),
        );
        //}
      }

      if (raiseLimitTS) {
        if (Date.now() + serverTimeDeviceTimeOffset < raiseLimitTS) {
          setIsPaused(false); // resume auction (offline bids make the auction pause)
          return setRaiseLimitTS(raiseLimitTS);
        }
      }
    });

    // online subscription events
    useOnlineSubscriptionHandler(props.id, (data) => {
      const { ts, participationId, participationType } = data;
      // const now = new Date();
      // const fiveSecondsBeforeNow = new Date(now.getTime() - 5000);
      if (ts < Date.now() + serverTimeDeviceTimeOffset - 5000) return; // ignore old subscriptions
      setNewFrontEndParticipant({
        participationId: participationId,
        participationType: participationType, // standard / observer / a107
      });
    });

    //connection notification
    firebase
      .database()
      .ref('.info/connected')
      .on('value', (snap) => {
        setConnectionOk(snap.val() === true);
        if (isActive) {
          setConnectionOk(true);
        }
      });
  });

  const getBestOffer = (): Offer => {
    const lastOfferIndex = offers.length - 1;
    if (offers.length > 1) {
      if (offers[lastOfferIndex].bidType === 'bid-edit') {
        const offer = offers[lastOfferIndex];
        offer.editable = false;
        offers[lastOfferIndex].editable = false;
        return offer;
      } else if (offers[lastOfferIndex].bidType === 'bid-cancellation') {
        const offer = offers[lastOfferIndex - 2];
        offer.editable = false;
        offers[lastOfferIndex].editable = false;
        return offer;
      } else return offers[lastOfferIndex];
    }
    return offers[0];
  };

  const isAuctionCompleted = () =>
    saleExperiment.auctionStatus === 'completed' || !!completedAuction;

  /// endregion

  // Wait for data to be fetched, when coming from List RA temporarily gets the data from the list record before calling the API to get the actual record
  // (called "pre-render" in the React Admin doc, or something like that)
  if (props.mode !== 'create' && !saleExperiment.relatedItem?.relatedFolder) return <Fragment />;

  return (
    <AppTabbedForm {...props} maxWidth="lg">
      <AppFormTab label="Generale" disabledCards={props.mode === 'show'}>
        <>
          {cannotModify && (
            <div
              style={{
                backgroundColor: 'red',
                padding: '5px',
                border: '1px solid lightgray',
                borderRadius: '5px',
                marginBottom: '10px',
              }}
            >
              <Typography style={{ fontWeight: 'bold', color: 'white' }}>
                Attenzione! Non è più possibile modificare i dati della gara! Per qualsiasi
                necessità rivolgersi all’assistenza
              </Typography>
            </div>
          )}
          <SaleExperimentAuctionDetails
            mode={props.mode}
            record={props.record}
            resource={props.resource}
            cannotModify={cannotModify}
          />
        </>
      </AppFormTab>
      <AppFormTab
        label="Partecipanti"
        path="participants"
        disabled={isAdvertisement || isRetailSale || isBuyItNowSale}
      >
        {saleExperiment && saleExperiment.relatedItem && saleExperiment.relatedItem.relatedFolder && (
          <div
            style={{
              backgroundColor: 'white',
              border: '1px solid lightgray',
              borderRadius: '6px',
              padding: '5px',
              marginBottom: '5px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography style={{ fontWeight: 'bold' }}>Fascicolo</Typography>
              <Typography>
                {`: ${saleExperiment.relatedItem.relatedFolder.internalCode}${
                  saleExperiment.relatedItem.relatedFolder.procedureCode
                    ? '(' + saleExperiment.relatedItem.relatedFolder.procedureCode + ')'
                    : ''
                }`}
              </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography style={{ fontWeight: 'bold' }}>Lotto</Typography>
              <Typography>{`: ${saleExperiment.relatedItem.title}`}</Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography style={{ fontWeight: 'bold' }}>Codice lotto</Typography>
              <Typography>{`: ${saleExperiment.relatedItem.code}`}</Typography>
            </div>
          </div>
        )}
        {isExpressionOfInterestOrOffersCollection ? (
          <React.Fragment />
        ) : (
          <AuctionManagement
            isAsync={isAsync}
            isJudiciary={isJudiciary}
            bestOffer={bestOffer}
            isPaused={isPaused}
            isActive={isActive}
            raiseLimitTS={raiseLimitTS}
            completedAuction={completedAuction}
            reservePriceInfo={reservePriceInfo}
            record={props.record}
            mode={props.mode}
            isConnectionOk={isConnectionOk}
            serverTimeDeviceTimeOffset={serverTimeDeviceTimeOffset}
          />
        )}
        <AppCard title="Offerenti" expanded hideContent={props.mode === 'create'}>
          <AppRelatedResource
            relatedResource="/participations"
            showDivider={false}
            filter={{
              observer: false,
              a107: false,
            }}
            actions={[
              <ImportParticipationButton />,
              <ParticipationAddButton />,
              <ParticipationEditOrRemoveOfferButton
                saleExpRecord={props.record}
                offerSuggestion={
                  offerSuggestion -
                  (props.record.minRaiseRefTo === 'minRaisePercentage'
                    ? calcPercentageOfValue(offerSuggestion, props.record.minRaisePercentage)
                    : props.record.minRaise)
                }
                completedAuction={isAuctionCompleted()}
                bestOffer={bestOffer}
              />,
              <ParticipationAddOfferButton
                saleExpRecord={props.record}
                offerSuggestion={offerSuggestion}
                completedAuction={isAuctionCompleted()}
                bestBidderId={bestOffer?.bidder ?? 0}
              />,
              <AuctionControlButton saleExperiment={props.record} />,
            ]}
            manager={
              <AppParticipantManager
                anchor="right"
                observer={false}
                a107={false}
                minOffer={saleExperiment.minPrice ?? saleExperiment.basePrice}
                auctionStatus={saleExperiment.auctionStatus}
                isAsync={isAsync}
                newFrontEndParticipant={newFrontEndParticipant}
                geocoderRef={props.options.geocoderRef}
              />
            }
            classes={classes}
            actionsHidden={props.mode !== 'edit' || isAuctionCompleted()}
          >
            <AppDatagrid resource="participants" expand={<ParticipationDetails />}>
              {/* TODO
                   <GavelTwoTone />
                   - Componente per numeri di telefono (se ce n'è uno si vede direttamente, se sono di più mettiamo il primo e un tooltip con gli altri
                   - Aggiudicatario, serve un flag e la riga deve essere evidenziata
              */}
              <SelectField
                choices={ORIGIN_TYPES}
                source="origin"
                label="Origine"
                headerClassName={dgClasses.colOrigin}
              />
              {!isExpressionOfInterestOrOffersCollection && (
                <FunctionField
                  label="Autorizzato"
                  render={(record) => (
                    <AppTooltip
                      title={
                        record?.pending
                          ? 'In attesa di autorizzazione'
                          : record?.authorized
                          ? 'Autorizzato a partecipare'
                          : 'Non autorizzato a partecipare'
                      }
                    >
                      {record?.pending ? (
                        <PendingIcon className={dgClasses.cellPending} />
                      ) : record?.authorized ? (
                        <AuthorizedIcon className={dgClasses.cellAuthorized} />
                      ) : (
                        <UnauthorizedIcon className={dgClasses.cellUnauthorized} />
                      )}
                    </AppTooltip>
                  )}
                />
              )}
              <FunctionField
                label="Presentatore"
                headerClassName={dgClasses.colPresenter}
                render={(r) => {
                  const name =
                    r?.presenter.type === 'person'
                      ? `${r?.presenter.firstName} ${r?.presenter.lastName}`
                      : r?.presenter.businessName;
                  return (
                    <AppLinkToEntityField
                      targetResource={'participants'}
                      originName="offerenti"
                      value={name}
                      targetResourceId={() => r?.presenter.id}
                    />
                  );
                }}
              />
              <FunctionField
                label="Offerenti"
                headerClassName={dgClasses.colBidders}
                render={(record) =>
                  record?.bidders
                    ? record.bidders.length > 1
                      ? `${record.bidders.length} offerenti`
                      : `${record.bidders[0].firstName} ${record.bidders[0].lastName} (${
                          record.bidders[0].taxCode ?? '-'
                        }) ${record.bidders[0].isPresenter ? '- (presentatore)' : ''}`
                    : '-'
                }
              />
              <AppTextField
                source="amount"
                label="Prima offerta"
                type="amount"
                headerClassName={dgClasses.colAmount}
                cellClassName={dgClasses.colAmount}
              />
              <AppDateField
                source="offeredAt"
                label="Data/ora prima offerta"
                showTime
                headerClassName={dgClasses.colOfferedAt}
              />
              <AppTextField
                source="depositAmount"
                label="Importo cauzione"
                type="amount"
                headerClassName={dgClasses.colDepositAmount}
                cellClassName={dgClasses.colDepositAmount}
              />
              <FunctionField
                label="Costi servizio"
                headerClassName={dgClasses.colFeesAmount}
                cellClassName={dgClasses.colFeesAmount}
                render={(record) => {
                  return amountFormatter(
                    record?.payments
                      ?.filter((payment) => payment.paymentReason === 'deposit')
                      .reduce((prev, curr) => +prev + +curr.feesAmount, 0),
                  );
                }}
              />
              <FunctionField
                label="Metodo pagamento"
                headerClassName={dgClasses.colPaymentMethod}
                cellClassName={dgClasses.colPaymentMethod}
                render={(record) =>
                  record?.payments
                    ?.filter((payment) => payment.paymentReason === 'deposit')
                    .map(
                      (payment) =>
                        PAYMENT_METHODS.find(
                          (paymentMethod) => paymentMethod.id === payment.paymentMethod,
                        )?.name,
                    )
                    .join(', ')
                }
              />
              <GenerateNewPinButton />
              <AttachmentsButton />
              <ParticipationRowDownloadPdf saleExpRecord={props.record} />
              <ParticipationRowCheck
                saleExpRecord={props.record}
                disabled={props.mode !== 'edit'}
              />
            </AppDatagrid>
          </AppRelatedResource>
        </AppCard>
        {props.record.observersOption !== 'participants-only' &&
        !isExpressionOfInterestOrOffersCollection ? (
          <AppCard title="Osservatori" hideContent={props.mode === 'create'}>
            <AppRelatedResource
              relatedResource="/participations"
              showDivider={false}
              filter={{
                observer: true,
                a107: false,
              }}
              actions={[<ParticipationAddButton observer />]}
              actionsHidden={props.mode !== 'edit'}
              manager={
                <AppParticipantManager
                  anchor="right"
                  observer
                  a107={false}
                  auctionStatus={saleExperiment.auctionStatus}
                  isAsync={isAsync}
                  newFrontEndParticipant={newFrontEndParticipant}
                  geocoderRef={props.options.geocoderRef}
                />
              }
              classes={classes}
            >
              <AppDatagrid
                rowStyle={(record) => (record.owner ? { backgroundColor: grey[300] } : {})}
              >
                <SelectField
                  choices={ORIGIN_TYPES}
                  source="origin"
                  label="Origine"
                  headerClassName={dgClasses.colOrigin}
                />
                <FunctionField
                  label="Autorizzato"
                  render={(record) => (
                    <AppTooltip
                      title={
                        record?.pending
                          ? 'In attesa di autorizzazione'
                          : record?.authorized
                          ? 'Autorizzato a partecipare'
                          : 'Non autorizzato a partecipare'
                      }
                    >
                      {record?.pending ? (
                        <PendingIcon className={dgClasses.cellPending} />
                      ) : record?.authorized ? (
                        <AuthorizedIcon className={dgClasses.cellAuthorized} />
                      ) : (
                        <UnauthorizedIcon className={dgClasses.cellUnauthorized} />
                      )}
                    </AppTooltip>
                  )}
                />
                <FunctionField
                  label="Presentatore"
                  headerClassName={dgClasses.colFullWidth}
                  render={(r) => {
                    const name =
                      r?.presenter.type === 'person'
                        ? `${r?.presenter.firstName} ${r?.presenter.lastName}`
                        : r?.presenter.businessName;
                    return (
                      <AppLinkToEntityField
                        targetResource={'participants'}
                        originName="osservatori"
                        value={name}
                        targetResourceId={() => r?.presenter.id}
                      />
                    );
                  }}
                />
                <AppTextField
                  source="observerReason"
                  label="Motivazione"
                  headerClassName={dgClasses.colObserverReason}
                />
                <ObserverRowDownloadPdf saleExpRecord={props.record} />
                <ParticipationRowCheck
                  saleExpRecord={props.record}
                  disabled={props.mode !== 'edit'}
                />
              </AppDatagrid>
            </AppRelatedResource>
          </AppCard>
        ) : (
          <React.Fragment />
        )}
        {!isExpressionOfInterestOrOffersCollection && props.record.a107 ? (
          <AppCard title="Offerenti Art.107 L.F." hideContent={props.mode === 'create'}>
            <AppRelatedResource
              relatedResource="/participations"
              showDivider={false}
              filter={{
                observer: false,
                a107: true,
              }}
              actions={[<ParticipationAddButton a107 />]}
              actionsHidden={props.mode !== 'edit'}
              manager={
                <AppParticipantManager
                  anchor="right"
                  observer={false}
                  a107
                  auctionStatus={saleExperiment.auctionStatus}
                  isAsync={isAsync}
                  newFrontEndParticipant={newFrontEndParticipant}
                  geocoderRef={props.options.geocoderRef}
                />
              }
              classes={classes}
            >
              <AppDatagrid resource="participants" expand={<ParticipationDetails />}>
                <SelectField
                  choices={ORIGIN_TYPES}
                  source="origin"
                  label="Origine"
                  headerClassName={dgClasses.colOrigin}
                />
                <FunctionField
                  label="Autorizzato"
                  render={(record) => (
                    <AppTooltip
                      title={
                        record?.pending
                          ? 'In attesa di autorizzazione'
                          : record?.authorized
                          ? 'Autorizzato a partecipare'
                          : 'Non autorizzato a partecipare'
                      }
                    >
                      {record?.pending ? (
                        <PendingIcon className={dgClasses.cellPending} />
                      ) : record?.authorized ? (
                        <AuthorizedIcon className={dgClasses.cellAuthorized} />
                      ) : (
                        <UnauthorizedIcon className={dgClasses.cellUnauthorized} />
                      )}
                    </AppTooltip>
                  )}
                />
                <FunctionField
                  label="Presentatore"
                  headerClassName={dgClasses.colFullWidth}
                  render={(r) => {
                    const name =
                      r?.presenter.type === 'person'
                        ? `${r?.presenter.firstName} ${r?.presenter.lastName}`
                        : r?.presenter.businessName;
                    return (
                      <AppLinkToEntityField
                        targetResource={'participants'}
                        originName="osservatori"
                        value={name}
                        targetResourceId={() => r?.presenter.id}
                      />
                    );
                  }}
                />
                <FunctionField
                  label="Offerenti"
                  headerClassName={dgClasses.colBidders}
                  render={(record) =>
                    record?.bidders.length
                      ? record.bidders.length > 1
                        ? `${record.bidders.length} offerenti`
                        : `${record.bidders[0].firstName} ${record.bidders[0].lastName} (${
                            record.bidders[0].taxCode ?? '-'
                          }) ${record.bidders[0].isPresenter ? '- (presentatore)' : ''}`
                      : '-'
                  }
                />
                <AppTextField
                  source="amount"
                  label="Offerta"
                  type="amount"
                  headerClassName={dgClasses.colAmount}
                  cellClassName={dgClasses.colAmount}
                />
                <AppDateField
                  source="offeredAt"
                  label="Data/ora offerta"
                  showTime
                  headerClassName={dgClasses.colOfferedAt}
                  emptyText="-"
                />
                <AppTextField
                  source="depositAmount"
                  label="Importo cauzione"
                  type="amount"
                  headerClassName={dgClasses.colDepositAmount}
                  cellClassName={dgClasses.colDepositAmount}
                />
                <AttachmentsButton mode={props.mode} />
                <ParticipationRowDownloadPdf saleExpRecord={props.record} />
                <ParticipationRowCheck
                  saleExpRecord={props.record}
                  disabled={props.mode !== 'edit'}
                  a107
                />
              </AppDatagrid>
            </AppRelatedResource>
          </AppCard>
        ) : (
          <React.Fragment />
        )}
        <AppCard title="Tentativi di partecipazione" expanded hideContent={props.mode === 'create'}>
          <AppRelatedResource
            relatedResource="/participation-attempts"
            showDivider={false}
            classes={classes}
            actions={[]}
          >
            <AppDatagrid>
              <AppDateField
                source="createdAt"
                label="Data richiesta"
                showTime
                headerClassName={classes.createdAt}
              />
              <FunctionField
                label="Anagrafica richiedente"
                render={(r) => {
                  const name =
                    r?.frontEndParticipant.type === 'person'
                      ? `${r?.frontEndParticipant.firstName} ${r?.frontEndParticipant.lastName}`
                      : r?.frontEndParticipant.businessName;
                  return (
                    <AppLinkToEntityField
                      targetResource={'participants'}
                      originName="tentativi di partecipazione"
                      value={name}
                      targetResourceId={() => r?.frontEndParticipant.id}
                    />
                  );
                }}
                headerClassName={classes.name}
              />
              <AppTextField
                source="amount"
                label="Offerta"
                type="amount"
                headerClassName={classes.amount}
                cellClassName={classes.amount}
              />
              <AppTextField
                source="errorMessage"
                label="Errore"
                headerClassName={classes.errorMessage}
                cellClassName={classes.errorMessage}
              />
              <ArrayField source={`${PARTICIPANT_BASE_SOURCE}.mobiles`} label="Cellulare">
                <SingleFieldList>
                  <ChipField source="number" />
                </SingleFieldList>
              </ArrayField>
              <AppTextField source={`${PARTICIPANT_BASE_SOURCE}.email`} label="Email" />
              <SelectField
                choices={PERSON_TYPES}
                source={`${PARTICIPANT_BASE_SOURCE}.type`}
                label="Tipo persona"
                headerClassName={classes.lastCol}
              />
            </AppDatagrid>
          </AppRelatedResource>
        </AppCard>
      </AppFormTab>
      <AppFormTab
        label="Esito pubblicità"
        disabled={!isAdvertisement && !isBuyItNowSale}
        path="advertisement-outcome"
        maxWidth="md"
      >
        <SaleExperimentAdvertisementOutcome mode={props.mode} />
      </AppFormTab>
      <AppFormTab
        label="Logs"
        path="logs"
        disabled={isAdvertisement || isRetailSale}
        maxWidth={false}
      >
        <SaleExperimentDetailsLog {...props} />
      </AppFormTab>
      <AppFormTab
        label="Spedizioni"
        path="shippings"
        disabled={
          isAdvertisement ||
          isRetailSale ||
          IS_IVG ||
          !(saleExperiment.relatedItem.frontEndSale || saleExperiment.relatedItem.thirdLevel)
        }
        maxWidth={false}
      >
        <SaleExperimentDetailsShipping {...props} />
      </AppFormTab>
    </AppTabbedForm>
  );
});

export default SaleExperimentDetails;
