import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AddCircleOutline } from '@material-ui/icons';
import arrayMutators from 'final-form-arrays';
import { FC, Fragment, useEffect, useState } from 'react';
import { useMutation, useNotify, useRefresh } from 'react-admin';
import { Form } from 'react-final-form';
import { useBasePath } from '../../hooks/use-base-path';
import { OriginType } from '../../utils/constants';
import { minValue } from '../../utils/validators';
import { AppAutocompleteInput, AppNumberInput, AppTextInput } from '../ui/input';

const useStyles = makeStyles(
  (theme) => ({
    newOfferButton: (props: Record<string, any>) => ({
      minWidth: theme.spacing(40),
      marginTop: -theme.spacing(5.5),
      marginRight: theme.spacing(props.isPaused ? 75 : 85),
    }),
    formDialog: {
      width: theme.spacing(110),
      height: theme.spacing(60),
      maxWidth: 'none',
    },
    confirmationDialog: {
      width: theme.spacing(60),
      height: theme.spacing(30),
      maxWidth: 'none',
    },
  }),
  { name: 'ParticipationEditOrRemoveOfferButton' },
);

const ParticipationEditOrRemoveOfferButton: FC<any> = (props) => {
  const { saleExpRecord, offerSuggestion, completedAuction = false, bestOffer } = props;
  const { auctionStatus, id: saleExpId, fkSaleMode, auctionIsPaused } = saleExpRecord;
  const classes = useStyles({
    isPaused: auctionIsPaused,
  });
  const notify = useNotify();
  const refresh = useRefresh();

  const basePath = useBasePath();

  const [participantChoices, setParticipantChoices] = useState<Record<string, any>[]>([]);

  const [amountSuggestion, setAmountSuggestion] = useState<number>(offerSuggestion);
  const [editAction, setEditAction] = useState<boolean>(true);
  const [isVisible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (
      saleExpRecord.relatedParticipations &&
      bestOffer &&
      bestOffer.type === 'bid' &&
      bestOffer.editable === true
    ) {
      // bid -> edit =>
      const participation = saleExpRecord.relatedParticipations.find(
        (p) => p.origin === OriginType.Paper && p.authorized && p.id === bestOffer.bidder,
      );
      if (participation) {
        setParticipantChoices([
          {
            id: participation.id,
            name: `${participation.auctionId} - ${participation.presenter.lastName} ${participation.presenter.firstName}`,
          },
        ]);
      }

      setParticipantChoice(participation?.id ?? 0);
      setAmountSuggestion(bestOffer?.offer ?? amountSuggestion);
      setVisible(
        !completedAuction && fkSaleMode === 3 && auctionStatus === 'active' && participation,
      );
    }
  }, [saleExpRecord, bestOffer]);

  // MUTATE AUCTION STATUS
  const [mutateAuctionIsPaused] = useMutation();
  const handleAuctionPauseToggle = () =>
    mutateAuctionIsPaused(
      {
        type: 'create',
        resource: `${basePath}/${saleExpId}/toggle-auction-is-paused`,
        payload: {
          data: {
            id: saleExpId,
            offering: true,
          },
        },
      },
      {
        onSuccess: (res) => {
          if (res.data.auctionIsPaused) {
            notify("L'asta è attualmente in pausa.", 'info');
            setEditOfferDialogOpen(true);
          } else {
            setEditOfferDialogOpen(false);
            setResumeAuctionDialogOpen(false);
            notify("L'asta è attualmente in corso.", 'info');
            refresh();
          }
        },
        onFailure: (err) => {
          notify(err.message, 'error');
          console.log(err);
        },
      },
    );

  // RESUME DIALOG
  const [resumeAuctionDialogIsOpen, setResumeAuctionDialogOpen] = useState(false);
  const handleResumeAuctionDialogClose = () => {
    setResumeAuctionDialogOpen(false);
    refresh();
  };

  /// region ADD OFFER

  const [editOfferDialogIsOpen, setEditOfferDialogOpen] = useState(false);
  const [offerAmount, setOfferAmount] = useState<number>(offerSuggestion);
  const [participantChoice, setParticipantChoice] = useState<number>(0);
  const [notes, setNotes] = useState<string>('');

  useEffect(() => {
    // Always update offer amount after offer suggestion changes (min offer)
    setOfferAmount(offerSuggestion);
  }, [offerSuggestion]);

  const [addNewOffer] = useMutation();
  const handleNewOfferDialog = () => {
    auctionIsPaused ? setEditOfferDialogOpen(true) : handleAuctionPauseToggle();
  };

  const [removeOfferAlertVisible, setRemoveOfferAlertVisible] = useState(false);

  const handleEditOrRemoveOffer = () =>
    addNewOffer(
      {
        type: 'create',
        resource: `${basePath}/${saleExpId}/edit-offline-bid/${participantChoice}`,
        payload: {
          data: {
            id: participantChoice,
            amount: Number(offerAmount),
            edit: editAction,
            notes,
          },
        },
      },
      {
        onSuccess: () => {
          notify(`Offerta ${editAction ? 'modificata' : 'rimossa'} correttamente.`, 'info');
          handleAuctionPauseToggle();
        },
        onFailure: (err) => {
          console.error(err);
          notify(
            `Non è stato possibile ${editAction ? 'modificare' : 'rimuovere'} l'ultimo rilancio.`,
            'error',
          );
        },
      },
    );

  const handleConfirmEditOffer = (edit: boolean) => {
    setRemoveOfferAlertVisible(true);
    setEditAction(edit);
  };

  /// endregion

  return isVisible ? (
    <Fragment>
      <Button
        children="Modifica ultimo rilancio dalla sala"
        variant="contained"
        color="primary"
        startIcon={<AddCircleOutline />}
        className={classes.newOfferButton}
        onClick={handleNewOfferDialog}
      />
      <Dialog open={editOfferDialogIsOpen} classes={{ paper: classes.formDialog }}>
        <DialogTitle>Modifica/cancellazione ultimo rilancio - Asta in pausa</DialogTitle>
        <Form
          onSubmit={() => handleConfirmEditOffer(true)}
          mutators={arrayMutators as any}
          initialValues={{
            amount: amountSuggestion,
          }}
          render={(formProps) => {
            const { submitting, hasValidationErrors } = formProps;

            return (
              <Fragment>
                <DialogContent>
                  <Grid container spacing={2}>
                    <AppAutocompleteInput
                      source="participant"
                      label="Partecipante"
                      variant="outlined"
                      choices={participantChoices}
                      defaultValue={participantChoice}
                      onSelect={(participant) => setParticipantChoice(participant.id)}
                      required
                    />
                    <AppNumberInput
                      source="amount"
                      label="Ammontare ultimo rilancio"
                      isAmount
                      variant="outlined"
                      helperText="L'importo suggerito corrisponde all'ultima offerta pervenuta dalla sala oltre il rilancio minimo"
                      required
                      onChange={(event) => setOfferAmount(event.target.value)}
                      validate={[minValue(offerSuggestion, 'offerta minima', true)]}
                    />
                    <AppTextInput
                      source="notes"
                      label="Motivo rettifica rilancio"
                      multiline
                      onChange={(event) => setNotes(event.target.value)}
                    />
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    children="Cancella rilancio"
                    variant="contained"
                    color="primary"
                    onClick={() => handleConfirmEditOffer(false)}
                  />
                  <Button
                    children="Annulla"
                    onClick={() => {
                      setEditOfferDialogOpen(false);
                      setResumeAuctionDialogOpen(true);
                    }}
                  />
                  <Button
                    children="Conferma modifica"
                    variant="contained"
                    color="primary"
                    onClick={() => handleConfirmEditOffer(true)}
                    disabled={submitting || hasValidationErrors}
                  />
                </DialogActions>
              </Fragment>
            );
          }}
        />
      </Dialog>

      <Dialog open={removeOfferAlertVisible}>
        <DialogTitle>Attenzione</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sei sicuro di voler {editAction ? 'modificare' : 'cancellare'} l'ultimo rilancio
            avvenuto dalla sala?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            children="NO"
            onClick={() => {
              setRemoveOfferAlertVisible(false);
              setEditOfferDialogOpen(true);
            }}
          />
          <Button
            children="SI"
            variant="contained"
            color="primary"
            onClick={handleEditOrRemoveOffer}
          />
        </DialogActions>
      </Dialog>

      <Dialog open={resumeAuctionDialogIsOpen} classes={{ paper: classes.confirmationDialog }}>
        <DialogTitle>Attenzione - Riavvio gara</DialogTitle>
        <DialogContent>
          <DialogContentText>Riavviare la gara?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button children="NO" onClick={handleResumeAuctionDialogClose} />
          <Button
            children="SI"
            color="primary"
            variant="contained"
            onClick={handleAuctionPauseToggle}
          />
        </DialogActions>
      </Dialog>
    </Fragment>
  ) : (
    <span />
  );
};

export default ParticipationEditOrRemoveOfferButton;
