import { Typography } from '@material-ui/core';
import { FunctionComponent, useEffect, useState } from 'react';
import { ListProps } from 'react-admin';

const ClicksCounterList: FunctionComponent<ListProps> = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const [totalCount, setTotalCount] = useState<number>(0);
  const [loadingTotalCount, setLoadingTotalCount] = useState<boolean>(true);
  const [errorLoadingTotalCount, setErrorLoadingTotalCount] = useState<boolean>(false);
  const [totalCountCurrentDay, setTotalCountCurrentDay] = useState<number>(0);
  const [loadingTotalCountCurrentDay, setLoadingTotalCountCurrentDay] = useState<boolean>(true);
  const [errorLoadingTotalCountCurrentDay, setErrorLoadingTotalCountCurrentDay] =
    useState<boolean>(false);
  const [totalCountCurrentWeek, setTotalCountCurrentWeek] = useState<number>(0);
  const [loadingTotalCountCurrentWeek, setLoadingTotalCountCurrentWeek] = useState<boolean>(true);
  const [errorLoadingTotalCountCurrentWeek, setErrorLoadingTotalCountCurrentWeek] =
    useState<boolean>(false);
  const [totalCountCurrentMonth, setTotalCountCurrentMonth] = useState<number>(0);
  const [loadingTotalCountCurrentMonth, setLoadingTotalCountCurrentMonth] = useState<boolean>(true);
  const [errorLoadingTotalCountCurrentMonth, setErrorLoadingTotalCountCurrentMonth] =
    useState<boolean>(false);

  const getTotalCount = async () => {
    fetch(`${apiUrl}/info-requests/clicks`)
      .then((response) => {
        response
          .json()
          .then((data) => {
            if ((data.statusCode && data.statusCode !== 200) || !data.totalClicks) {
              setErrorLoadingTotalCount(true);
              setLoadingTotalCount(false);
              return;
            }
            const apiTotalCount = data.totalClicks ?? 0;
            setTotalCount(apiTotalCount);
            setLoadingTotalCount(false);
          })
          .catch((error) => {
            console.error('Error fetching total count', error);
            setErrorLoadingTotalCount(true);
          });
      })
      .catch((error) => {
        console.error('Error fetching total count', error);
        setErrorLoadingTotalCount(true);
      });
  };

  const getTotalCountCurrentDay = async () => {
    fetch(`${apiUrl}/info-requests/clicks-current-day`)
      .then((response) => {
        response
          .json()
          .then((data) => {
            if ((data.statusCode && data.statusCode !== 200) || !data.totalClicks) {
              setErrorLoadingTotalCountCurrentDay(true);
              setLoadingTotalCountCurrentDay(false);
              return;
            }
            const apiTotalCountCurrentDay = data.totalClicks ?? 0;
            setTotalCountCurrentDay(apiTotalCountCurrentDay);
            setLoadingTotalCountCurrentDay(false);
          })
          .catch((error) => {
            console.error('Error fetching total count current day', error);
            setErrorLoadingTotalCountCurrentDay(true);
          });
      })
      .catch((error) => {
        console.error('Error fetching total count current day', error);
        setErrorLoadingTotalCountCurrentDay(true);
      });
  };

  const getTotalCountCurrentWeek = async () => {
    fetch(`${apiUrl}/info-requests/clicks-current-week`)
      .then((response) => {
        response
          .json()
          .then((data) => {
            if ((data.statusCode && data.statusCode !== 200) || !data.totalClicks) {
              setErrorLoadingTotalCountCurrentWeek(true);
              setLoadingTotalCountCurrentWeek(false);
              return;
            }
            const apiTotalCountCurrentWeek = data.totalClicks ?? 0;
            setTotalCountCurrentWeek(apiTotalCountCurrentWeek);
            setLoadingTotalCountCurrentWeek(false);
          })
          .catch((error) => {
            console.error('Error fetching total count current week', error);
            setErrorLoadingTotalCountCurrentWeek(true);
          });
      })
      .catch((error) => {
        console.error('Error fetching total count current week', error);
        setErrorLoadingTotalCountCurrentWeek(true);
      });
  };

  const getTotalCountCurrentMonth = async () => {
    fetch(`${apiUrl}/info-requests/clicks-current-month`)
      .then((response) => {
        response
          .json()
          .then((data) => {
            if ((data.statusCode && data.statusCode !== 200) || !data.totalClicks) {
              setErrorLoadingTotalCountCurrentMonth(true);
              setLoadingTotalCountCurrentMonth(false);
              return;
            }
            const apiTotalCountCurrentMonth = data.totalClicks ?? 0;
            setTotalCountCurrentMonth(apiTotalCountCurrentMonth);
            setLoadingTotalCountCurrentMonth(false);
          })
          .catch((error) => {
            console.error('Error fetching total count current month', error);
            setErrorLoadingTotalCountCurrentMonth(true);
          });
      })
      .catch((error) => {
        console.error('Error fetching total count current month', error);
        setErrorLoadingTotalCountCurrentMonth(true);
      });
  };

  useEffect(() => {
    getTotalCount();
    getTotalCountCurrentDay();
    getTotalCountCurrentWeek();
    getTotalCountCurrentMonth();
  }, []);

  return (
    <div style={{ overflowY: 'scroll', backgroundColor: 'white' }}>
      <Typography style={{ fontWeight: 'bold', margin: '30px' }} variant="h4">
        CONTATORE CLICK
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '30px' }} variant="h6">
        Totale click:{' '}
        {loadingTotalCount ? 'Caricamento...' : errorLoadingTotalCount ? 'ERRORE' : totalCount}
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '30px' }} variant="h6">
        Click di oggi:{' '}
        {loadingTotalCountCurrentDay
          ? 'Caricamento...'
          : errorLoadingTotalCountCurrentDay
          ? 'ERRORE'
          : totalCountCurrentDay}
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '30px' }} variant="h6">
        Click di questa settimana:{' '}
        {loadingTotalCountCurrentWeek
          ? 'Caricamento...'
          : errorLoadingTotalCountCurrentWeek
          ? 'ERRORE'
          : totalCountCurrentWeek}
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '30px' }} variant="h6">
        Click di questo mese:{' '}
        {loadingTotalCountCurrentMonth
          ? 'Caricamento...'
          : errorLoadingTotalCountCurrentMonth
          ? 'ERRORE'
          : totalCountCurrentMonth}
      </Typography>
    </div>
  );
};

export default ClicksCounterList;
