import { FC, Fragment, useMemo } from 'react';
import AppCard from '../../../Components/ui/card/AppCard';
import {
  AppBooleanInput,
  AppDateTimeInput,
  AppInputFiller,
  AppTextInput,
} from '../../../Components/ui/input';
import AppDateCalculator from '../../../Components/ui/input/AppDateCalculator';
import { useCmsConfiguration } from '../../../hooks/use-cms-configuration';
import { SaleMode } from '../../../utils/constants';
import { folderIsJudiciary } from '../../../utils/folder';
import { isDetailComponentVisible } from '../../../utils/sale-experiment';
import { maxDate } from '../../../utils/validators';

const WHERE_IS_VISIBLE = [
  SaleMode.AtSeller,
  SaleMode.SyncOnline,
  SaleMode.SyncMixed,
  SaleMode.AsyncOnline,
  SaleMode.ExpressionOfInterest,
  SaleMode.WithAuction,
];

const IS_IVG = process.env.REACT_APP_IS_IVG === 'true';

export const AuctionVisit: FC<any> = ({ formData, mode, record, selectedItem }) => {
  const { fkSaleMode } = formData;

  const isVisible = useMemo(
    () => isDetailComponentVisible(fkSaleMode, WHERE_IS_VISIBLE),
    [fkSaleMode],
  );

  const isJudiciary = useMemo(
    () => folderIsJudiciary(selectedItem.relatedFolder),
    [selectedItem.relatedFolder],
  );

  const cmsConfig = useCmsConfiguration();

  return isVisible ? (
    <AppCard title="Visite" disabled={mode === 'show'}>
      <AppDateTimeInput
        source="visitStartAt"
        label="Data/ora inizio visita"
        md={3}
        variant="outlined"
        helperText={
          mode === 'create' ? 'Se lasciato vuoto verrà salvata la data corrente' : undefined
        }
      />
      {isJudiciary && (
        <AppBooleanInput
          source="allowBookingThroughPvp"
          md={4}
          label="Consentire richieste di visita tramite PVP?"
          initialValue={!!cmsConfig?.websiteCustomization?.Generic?.AllowBookingThroughPvp}
        />
      )}
      <AppInputFiller md={isJudiciary ? 5 : 9} />
      <AppDateCalculator
        refToSource="visitEndAtRefTo"
        hasTime={true}
        source="visitEndAt"
        label="Data/ora fine visita"
        validate={[maxDate('auctionStartAt', 'Inizio asta')]}
        refToChoices={[{ id: 'auctionStartAt', name: 'Inizio asta' }]}
        record={record}
        mode={mode}
      />
      <AppDateTimeInput
        source="bookingsEndAt"
        label="Termine prenotazione visite"
        md={3}
        variant="outlined"
      />
      <AppBooleanInput
        source="publishBookingsEndAt"
        md={4}
        label="Pubblicare il termine prenotazione visite sul sito?"
      />
      <AppTextInput
        source="visitNotes"
        label="Note sulle visite"
        multiline
        expandable
        md={10}
        variant="outlined"
      />
      <AppBooleanInput source="publishVisitNotes" md={2} label="Pubblicare le note sul sito?" />
      <AppTextInput
        source="visitNotIvgNotes"
        label={
          IS_IVG
            ? 'Note sulle visite non IVG'
            : 'Ulteriori note sulle visite (compilando questo campo non sarà possibile richiedere le visite tramite il sito)'
        }
        multiline
        expandable
        md={10}
        variant="outlined"
      />
    </AppCard>
  ) : (
    <Fragment />
  );
};
